/* ============================================	*/
/* TABLE OF CONTENTS							*/
/* ============================================	*/
/* ============================================	*/
/* 	form styles									*/
/* 		checkboxes, radios						*/
/* 		inputs, textareas						*/
/* 			inputs feedbacks					*/
/* 	button styles								*/
/* 		styles 									*/
/* 		with icons								*/
/* 		bg colors								*/
/* 		bg gradients							*/
/* 	typography styles							*/
/* 		tags 									*/
/* 	table styles								*/
/* 		header, body							*/
/* 	modal styles								*/
/* 		header, body, footer					*/
/* ============================================ */


/* ========================	*/
/* form styles 				*/
/* ========================	*/
/* ------- checkbox ------- */

@trailer-checkbox-selected-bg-color: @trailer-dark-gray;
@trailer-checkbox-border-color: @trailer-gray;

.trailer-checkbox {
	.checkboxLabel {
		/* this will make the element as wide as the label */
		position: relative;
	}
	label.tick-holder {
		background-color: white;
		width: 20px;
		height: 20px;
		border: 1px solid @trailer-checkbox-border-color;
		border-radius: 0px;
		padding: 2px;
		text-align: center;		
		margin: 0px 0px 0px 10px;
		vertical-align: middle;
		position: relative;
		font-weight: normal;
		line-height: normal;
		.tick {
			width: 14px;
			height: 14px;
			display: block;
			position: relative;
			background-color: white;
			padding: 0px;
			border-radius: 3px;
		}
	}
	input[type=checkbox],
	input[type=radio] {
		position: absolute;
		height: 21px;
		/* to make the whole label clickable */
		width: 100%;
		margin: 0px;
		opacity: 0.001;
		z-index: 1;
		cursor: pointer;
	}		
	input[type=checkbox]:checked ~ .tick-holder .tick,
	input[type=radio]:checked ~ .tick-holder .tick {
		background-color: @trailer-checkbox-selected-bg-color;
	}
}
/* ----- input fields ----- */

.trailer-form {
	input, .input-group-addon, textarea {
		border-radius: 0px;
		border-right-style: none;
		border-image: none;
		box-shadow: none;
	}
	.input-group-addon,
	input,
	textarea {
		background-color: @trailer-light-gray;
		color: black;
	}
	.input-group-addon {
		border-top: thin solid @trailer-dark-gray;
		padding: 6px;
		i {
			font-size: 18px;
		}
	}
	input {
		height: 40px;
	}
	textarea {
		padding: 15px;
		resize: vertical;
	}
	input, textarea {
		border-left: thin solid @trailer-dark-gray;
		border-top: thin solid @trailer-dark-gray;
		border-right: thin solid @trailer-gray;
		border-bottom: thin solid @trailer-gray;
		&::-webkit-input-placeholder {
		   color: black;
		   font-size: 15px;
		   font-weight: 300;
		}

		&:-moz-placeholder {
		   color: black;
		   font-size: 15px;
		   font-weight: 300;  
		}

		&::-moz-placeholder {
		   color: black;
		   font-size: 15px;
		   font-weight: 300;  
		}

		&:-ms-input-placeholder {  
		   color: black;
		   font-size: 15px;
		   font-weight: 300;  
		}
	}
	.form-control:focus {
		border-left-color: @trailer-dark-gray;
		border-top-color: @trailer-dark-gray;
		border-right-color: @trailer-gray;
		border-bottom-color: @trailer-gray;
		box-shadow: none;
	}
	.form-group.has-success .feedback.good, 
	.form-group.has-warning .feedback.warn, 
	.form-group.has-error .feedback.bad {
		display: block
	}
	.feedback {
		display: none;
		padding: 10px 5px 10px 0px;
		background-color: transparent;
		font-weight: 300;
		i {
			margin: 5px 10px 5px 5px;
			width: 20px;
			text-align: center;
			height: 20px;
			padding: 3px;
			border-radius: 3px;
			color: white;
		}
	}
	.feedback.good {
		color: @trailer-feedback-good;
		i {
			background-color: @trailer-feedback-good;			
		}
	}
	.feedback.bad {
		color: @trailer-feedback-bad;
		i {
			background-color: @trailer-feedback-bad;
		}
	}
	.feedback.warn {
		color: @trailer-feedback-warn;
		i {
			background-color: @trailer-feedback-warn;
		}
	}
	.feedback.info {
		display: block;
		color: @trailer-feedback-info;
		i {
			background-color: @trailer-feedback-info;
		}
	}
	/* some adjustment for the address fields */
	.address {
		height: 40px;		
		.side-padding:first-of-type {
			padding-left: 0px;
			padding-right: 10px;
		}
		.side-padding:last-of-type {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}


/* ========================	*/
/* button styles 			*/
/* ========================	*/
/* base */
.btn {
	font-size: 13px;
	font-family: 'Open Sans';
	padding: 5px 25px;
	a {
		color: @trailer-dark-gray;
	}
}

/* styles */
.btn.btn-sbold {
	font-weight: 600;
}

.btn.btn-xbold {
	font-weight: 800;
	font-style: italic;
}

/* with icon */
.btn.btn-icon {
	line-height: 25px;
	position: relative;
	span, i {
		vertical-align: middle;
		display: inline-block;
	}
	span {		
		font-weight: 600;
	}
	i {
		font-size: 20px;
		position: absolute;
		top: 50%;
		margin-top: -8px;		
	}
}

.btn.btn-icon.btn-icon-right {
	i {		
		right: 3px;
	}	
}

.btn.btn-icon.btn-icon-left {
	i {		
		left: 3px;
	}	
}

/* bg colors */
.btn.btn-white {
	background-color: white;
	color: @trailer-gray;
	border: 1px solid @trailer-gray;
	&:hover {
		background-color: @white-gradient-middle-color;
		border: 1px solid @white-gradient-middle-color;
	}
}

.btn.btn-yellow {
	background-color: @trailer-yellow;
	color: @trailer-dark-gray;
	&:hover {
		background-color: @yellow-gradient-top-color;
		border: 1px solid @yellow-gradient-middle-color;
	}
}

.btn.btn-lgray {
	background-color: @trailer-light-gray;
	color: @trailer-dark-gray;
	&:hover {
		background-color: @white-gradient-middle-color;
		border: 1px solid @white-gradient-bottom-color;
	}
}

.btn.btn-gray {
	background-color: @trailer-gray;
	color: @trailer-dark-gray;
	&:hover {
		background-color: @trailer-dark-gray;
		border: 1px solid @black-gradient-top-color;
		color: white;
	}
}

.btn.btn-dgray {
	background-color: @trailer-dark-gray;
	color: white;
	&:hover {
		background-color: @black-gradient-middle-color;
		border: 1px solid @black-gradient-bottom-color;
	}
}

/* bg gradients 					*/
/* buttons with gradient background */
.btn-black-gradient {
	border: thin solid @black-gradient-bottom-color;
	font-weight: 400;
	color: @trailer-yellow;
	.trailer-black-gradient;
	a {
		color: @trailer-yellow;
	}
	&:hover {
		color: @yellow-gradient-top-color;
	}
}

.btn-yellow-gradient {
	border: thin solid @yellow-gradient-bottom-color;
	font-weight: 400;
	color: @trailer-dark-gray;
	.trailer-yellow-gradient;
	a {
		color: @trailer-dark-gray;
	}
	&:hover {
		color: @black-gradient-bottom-color;
		span {
			color: @black-gradient-bottom-color;
		}
	}
}

.btn-orange-gradient {
	border: thin solid @orange-gradient-bottom-color;
	font-weight: 400;
	color: @white-gradient-top-color;
	.trailer-orange-gradient;
	a {
		color: @white-gradient-top-color;
	}
	&:hover {
		color: @black-gradient-top-color;
	}
}

.btn-gold-gradient {
	border: thin solid @gold-gradient-bottom-color;
	font-weight: 400;
	color: @trailer-dark-gray;
	.trailer-gold-gradient;
	a {
		color: @trailer-dark-gray;
	}
	&:hover {
		color: @black-gradient-top-color;
	}
}

.btn-white-gradient {
	border: thin solid @white-gradient-bottom-color;
	font-weight: 400;
	color: @trailer-dark-gray;
	.trailer-white-gradient;
	a {
		color: @trailer-dark-gray;
	}
	&:hover {
		color: @black-gradient-top-color;
	}
}

/* ========================	*/
/* typography styles		*/
/* ========================	*/

content {
	p {
		font-size: 17px;
		font-weight: 400;
		color: black;
	}
	h1 {
		font-weight: 700;
		font-style: italic;
		font-size: 28px;
		line-height: 50px;
		text-transform: uppercase;
	}
	h2 {
		font-weight: 600;
		font-style: italic;
		font-size: 24px;
		line-height: 40px;
		text-transform: uppercase;
	}
	h3 {
		font-weight: 700;
		font-style: italic;
		font-size: 22px;
		line-height: 33px;
		text-transform: uppercase;
	}
	h4 {
		font-weight: 600;
		font-size: 20px;
	}
	h5 {
		font-weight: 600;
		font-size: 18px;
	}
	h6 {
		font-weight: 400;
		font-size: 16px;
	}
}

/* ========================	*/
/* table styles	 			*/
/* ========================	*/

.trailer-table {
	width: 100%;
	border: 1px solid @trailer-gray;
	margin: 15px 0px 30px;
	th {
		background-color: @trailer-yellow;
		padding: 5px 5px 5px 15px;
		border-bottom: 2px solid @trailer-gray;
	}
	th,
	td {
		border-left: 1px solid @trailer-gray;
		border-top: 1px solid @trailer-gray;
	}
	td {
		text-align: center;
		font-weight: 400;
	}
	.header {
		background-color: @trailer-yellow;
	}
}
/* ========================	*/
/* modal styles	 			*/
/* ========================	*/
.modal {
	z-index: 1041;
	.modal-header,
	.modal-body,
	.modal-footer {
		border: none;
	}
	.modal-content {
		background-color: @white-gradient-middle-color;
		border-radius: 0px;
	}
	.modal-dialog {
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	.modal-header {
		.close {
			opacity: 1; 
			span {
				color: white;
				opacity: 1;
				font-weight: 300;
			}
		}
	}
	.modal-body {
		padding: 30px 15px;
		p, span {
			font-size: 16px;
		}
	}
}
.modal.good {
	.modal-header {
		background-color: @trailer-feedback-good;
	}
}
.modal.info {
	.modal-header {
		background-color: @trailer-feedback-info;
	}
}
.modal.warn {
	.modal-header {
		background-color: @trailer-feedback-warn;
	}
}
.modal.bad {
	.modal-header {
		background-color: @trailer-feedback-bad;
	}
}
.modal.in .modal-dialog {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.modal-backdrop.fade.in {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
}