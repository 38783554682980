/* ========================	*/
/* variables 				*/
/* ========================	*/
@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

/* ========================	*/
/* gradients 				*/
/* ========================	*/
/* black gradient colors 	*/
@black-gradient-top-color: 			#808285;
@black-gradient-middle-color: 		#58595B;
@black-gradient-bottom-color: 		#444444;

/* yellow gradient colors 	*/
@yellow-gradient-top-color: 		#fff39a;
@yellow-gradient-middle-color: 		#e8cb4e;
@yellow-gradient-bottom-color: 		#d8b72b;

/* orange gradient colors 	*/
@orange-gradient-top-color: 		#ffd597;
@orange-gradient-middle-color: 		#e89a4c;
@orange-gradient-bottom-color: 		#d88029;

/* gold gradient colors 	*/
@gold-gradient-top-color: 			#ffffe5;
@gold-gradient-middle-color: 		#e8e092;
@gold-gradient-bottom-color: 		#d8cf6b;

/* white gradient colors 	*/
@white-gradient-top-color: 			#ffffff;
@white-gradient-middle-color: 		#e8e7e0;
@white-gradient-bottom-color: 		#d8d6cd;


/* ----- base colors -----	*/
@trailer-black: 					#000000;
@trailer-yellow: 					#ffec4c;
@trailer-dark-gray: 				#848484;
@trailer-gray: 						#c6c6c6;
@trailer-light-gray: 				#f4f4f4;
@trailer-brown: 					#ad5514;

/* ---- feedback colors ---- */
@trailer-feedback-good: 			#8BE629;
@trailer-feedback-bad: 				#FE4365;
@trailer-feedback-warn:				#FBB829;
@trailer-feedback-info:				#61D2D6;

/* ---- table colors ---- */
@pdf-border: 1px solid black;
@compare-items-border: 1px solid @trailer-gray;

/* ---- gradient mixins ---- */
.trailer-black-gradient {
	background: @black-gradient-top-color;	
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgwODI4NSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzU4NTk1YiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0NDQ0NDQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  @black-gradient-top-color 0%, @black-gradient-middle-color 50%, @black-gradient-bottom-color 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@black-gradient-top-color), color-stop(50%,@black-gradient-middle-color), color-stop(100%,@black-gradient-bottom-color));
	background: -webkit-linear-gradient(top,  @black-gradient-top-color 0%,@black-gradient-middle-color 50%,@black-gradient-bottom-color 100%);
	background: -o-linear-gradient(top,  @black-gradient-top-color 0%,@black-gradient-middle-color 50%,@black-gradient-bottom-color 100%);
	background: -ms-linear-gradient(top,  @black-gradient-top-color 0%,@black-gradient-middle-color 50%,@black-gradient-bottom-color 100%);
	background: linear-gradient(to bottom,  @black-gradient-top-color 0%,@black-gradient-middle-color 50%,@black-gradient-bottom-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@black-gradient-top-color', endColorstr='@black-gradient-bottom-color',GradientType=0 );
}

.trailer-yellow-gradient {
	background: @yellow-gradient-top-color; 	
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZjM5YSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U4Y2I0ZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkOGI3MmIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  @yellow-gradient-top-color 0%, @yellow-gradient-middle-color 50%, @yellow-gradient-bottom-color 100%); 
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@yellow-gradient-top-color), color-stop(50%,@yellow-gradient-middle-color), color-stop(100%,@yellow-gradient-bottom-color)); 
	background: -webkit-linear-gradient(top,  @yellow-gradient-top-color 0%,@yellow-gradient-middle-color 50%,@yellow-gradient-bottom-color 100%); 
	background: -o-linear-gradient(top,  @yellow-gradient-top-color 0%,@yellow-gradient-middle-color 50%,@yellow-gradient-bottom-color 100%); 
	background: -ms-linear-gradient(top,  @yellow-gradient-top-color 0%,@yellow-gradient-middle-color 50%,@yellow-gradient-bottom-color 100%); 
	background: linear-gradient(to bottom,  @yellow-gradient-top-color 0%,@yellow-gradient-middle-color 50%,@yellow-gradient-bottom-color 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@yellow-gradient-top-color', endColorstr='@yellow-gradient-bottom-color',GradientType=0 ); 
}

.trailer-orange-gradient {
	background: @orange-gradient-top-color;
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZDU5NyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U4OWE0YyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkODgwMjkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  @orange-gradient-top-color 0%, @orange-gradient-middle-color 50%, @orange-gradient-bottom-color 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@orange-gradient-top-color), color-stop(50%,@orange-gradient-middle-color), color-stop(100%,@orange-gradient-bottom-color));
	background: -webkit-linear-gradient(top,  @orange-gradient-top-color 0%,@orange-gradient-middle-color 50%,@orange-gradient-bottom-color 100%);
	background: -o-linear-gradient(top,  @orange-gradient-top-color 0%,@orange-gradient-middle-color 50%,@orange-gradient-bottom-color 100%);
	background: -ms-linear-gradient(top,  @orange-gradient-top-color 0%,@orange-gradient-middle-color 50%,@orange-gradient-bottom-color 100%);
	background: linear-gradient(to bottom,  @orange-gradient-top-color 0%,@orange-gradient-middle-color 50%,@orange-gradient-bottom-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@orange-gradient-top-color', endColorstr='@orange-gradient-bottom-color',GradientType=0 );
}

.trailer-gold-gradient {
	background: @gold-gradient-top-color;
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZlNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U4ZTA5MiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkOGNmNmIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  @gold-gradient-top-color 0%, @gold-gradient-middle-color 50%, @gold-gradient-bottom-color 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@gold-gradient-top-color), color-stop(50%,@gold-gradient-middle-color), color-stop(100%,@gold-gradient-bottom-color));
	background: -webkit-linear-gradient(top,  @gold-gradient-top-color 0%,@gold-gradient-middle-color 50%,@gold-gradient-bottom-color 100%);
	background: -o-linear-gradient(top,  @gold-gradient-top-color 0%,@gold-gradient-middle-color 50%,@gold-gradient-bottom-color 100%);
	background: -ms-linear-gradient(top,  @gold-gradient-top-color 0%,@gold-gradient-middle-color 50%,@gold-gradient-bottom-color 100%);
	background: linear-gradient(to bottom,  @gold-gradient-top-color 0%,@gold-gradient-middle-color 50%,@gold-gradient-bottom-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@gold-gradient-top-color', endColorstr='@gold-gradient-bottom-color',GradientType=0 );
}

.trailer-white-gradient {
	background: @white-gradient-top-color;
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U4ZTdlMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkOGQ2Y2QiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  @white-gradient-top-color 0%, @white-gradient-middle-color 50%, @white-gradient-bottom-color 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@white-gradient-top-color), color-stop(50%,@white-gradient-middle-color), color-stop(100%,@white-gradient-bottom-color));
	background: -webkit-linear-gradient(top,  @white-gradient-top-color 0%,@white-gradient-middle-color 50%,@white-gradient-bottom-color 100%);
	background: -o-linear-gradient(top,  @white-gradient-top-color 0%,@white-gradient-middle-color 50%,@white-gradient-bottom-color 100%);
	background: -ms-linear-gradient(top,  @white-gradient-top-color 0%,@white-gradient-middle-color 50%,@white-gradient-bottom-color 100%);
	background: linear-gradient(to bottom,  @white-gradient-top-color 0%,@white-gradient-middle-color 50%,@white-gradient-bottom-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@white-gradient-top-color', endColorstr='@white-gradient-bottom-color',GradientType=0 );
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.container {
		width: 100%;
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.container {
		width: 100%;
	}
}
@media all and (min-width: 800px) and (max-width: 991px) {
	.container {
		padding-left: 20px;
		padding-right: 20px;
	}
}
