@import "bootstrap/bootstrap";
@import "trailer/settings";
@import "trailer/elements";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,700,800,800italic,300italic,400italic|Open+Sans+Condensed:300,700,300italic&subset=latin,latin-ext);

/* colors */
@nav-search-bg-color: @trailer-gray;
@nav-search-text-color: white;

body, label, .checkbox label {
	font-weight: 300;
}

body {
	font-family: 'Open Sans', sans-serif;
}

header {
	margin-top: 40px;
	.logolink,
	.logolink:hover,
	.logolink:visited {
		color: black;
		text-decoration: none;
	}
	.motto {
		font-weight: 600;
		font-size: 18px;
		margin: 5px 0; 
	}

	.partners {
		margin-top: 30px;
		padding-bottom: 10px;
		padding-left: 10px;
		border-bottom: 2px solid gray;
	}

	.partners img {
		margin-right: 10px;
	}

	.slogen {
		color: gray;
		font-weight: 600;
		font-size: 20px;
		white-space: nowrap;
	}
	.phone {
		padding-top: 30px;
		@media all and (min-width: 992px) {
			padding-left: 20%;
			text-align: right;	
		}			
		span {
			display: inline-block;
			float: left;
			text-align: left;
			font-size: 13px;
			text-transform: uppercase;
			font-weight: 400;
			color: @trailer-dark-gray;
		}
		span:nth-of-type(1),
		span:nth-of-type(2) {
			margin-right: 10px;
		}
		span:nth-of-type(4) {
			display: block;
			width: 100%;
			padding-top: 15px;
		}
		span:nth-of-type(5) {
			display: block;
			width: 100%;
			padding-top: 15px;
		}
	}
	.trailerbar {
		position: relative;
		@media all and (min-width: 768px) {
			padding-top: 30px;
		}		
		img {
			padding-right: 5px;
			display: inline-block;
			vertical-align: middle;
			@media all and (min-width: 500px) {
				width: 25%;
			}
			@media all and (max-width: 499px) {
				width: 50%;
			}
		}
	}
	@media all and (max-width: 767px) {
		nav.navbar {
			margin-bottom: 0px;
			border: none;
		}
	}
	nav {
		margin-top: 20px;		
		.container {
			@media all and (min-width: 768px) {
				padding-left: 0px;
				padding-right: 0px;
				ul.navbar-nav {
					width: 75%;
				}
				.navbar-form {
					width: 25%;
					.input-group {
						width: 100%;
					}
				}
				ul.navbar-nav,
				.navbar-form {
					float: left;
				}
			}
			
		}
	}	
	.navbar-form.trailer-form {
		margin-top: 0px;
		margin-bottom: 0px;
		.input-group-addon,
		input {
			background-color: @nav-search-bg-color;
			color: @nav-search-text-color;
		}
		input {
			border-bottom: none;
			border-right: none;		
			&::-webkit-input-placeholder {
			   color: @nav-search-text-color;
			   text-transform: uppercase;
			}

			&:-moz-placeholder {
			   color: @nav-search-text-color;
			   text-transform: uppercase;  
			}

			&::-moz-placeholder {
			   color: @nav-search-text-color;
			   text-transform: uppercase;  
			}

			&:-ms-input-placeholder {  
			   color: @nav-search-text-color;
			   text-transform: uppercase;  
			}
		}
		.form-control:focus {
			border-left-color: @trailer-dark-gray;
			border-top-color: @trailer-dark-gray;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			box-shadow: none;
		}
	}
	/* navbar styles */
	ul.navbar-nav {
		border-radius: 5px;
		.trailer-black-gradient;
		@media all and (min-width: 768px) {
			width: 66%;
			position: relative;
			&>li {				
				margin-left: 0px;
				a, i {
					color: white;
				}
				&:first-of-type,
				&:last-of-type {
					width: 30%;
				}
				&:nth-of-type(2),
				&:nth-of-type(3) {
					width: 20%;
				}
			}
		}
		li	{
			border-right: 1px solid #333;
			border-left: 1px solid @trailer-dark-gray;
			text-align: center;
			a {
				color: white;
				text-transform: uppercase;
				padding: 0px;
				line-height: 40px;
				&:hover {
					background-color: rgba(255,255,255,.2);
				}
			}
			.dropdown-toggle i {
				background-color: black;
				padding: 4px 3px 3px 3px;
				border-radius: 50%;
				width: 20px;
				height: 20px;
				position: absolute;
				right: 5px;
				top: 10px;
			}
		}
		li.dropdown {
			position: unset;
		}
		li:nth-of-type(1) {
			border-left: none;
		}
		li:last-of-type {
			border-right: none;
		}
		.dropdown-menu {
			background-color: @trailer-light-gray;
			width: 100%;
			border-radius: 5px;
			padding: 10px 15px;
			li {
				border: none;
				position: relative;
				&:hover {
					background-color: rgba(0, 0, 0, 0.1);
				}				
				@media all and (min-width: 1200px) {
					float: left;
					width: 33%;
				}
				@media all and (min-width: 991px) and (max-width: 1199px) {
					float: left;
					width: 50%;
				}			
				a {
					line-height: 50px;
					padding: 0px 20px;
					text-align: left;
					color: @trailer-dark-gray;
					font-weight: 600;
					font-size: 15px;
				}
				i {
					position: absolute;
					right: 10px;
					top: 50%;
					margin-top: -9px;
					color: @trailer-dark-gray;
					font-size: 16px;
				}
			}
		}
		#navDropDown {
			@media all and (max-width: 767px) {
				li>a, li>a>i {
					color: white;
				}
			}
			li>a>i {
				margin-top: -7px;
			}
		}
	}
	.nav .open > a, .nav .open > a:hover, .nav .open > a:focus, .nav > li > a:hover, .nav > li > a:focus {
		background-color: rgba(255,255,255,.2);
	}
	@media all and (max-width: 767px) {
		.navbar-header {
			.trailer-black-gradient;
			.navbar-toggle {
				.trailer-gold-gradient;
				.icon-bar {
					background-color: @trailer-dark-gray;
				}
			}
		}
		.navbar-collapse {
			.trailer-black-gradient;
		}
		ul.navbar-nav {
			padding: 15px 10px;
			margin-bottom: 0px;
			li {
				display: block;
				width: 100%;
				border: none;
			}
			.navbar-form {
				border: none;
				box-shadow: none;
			}
		}
	}
}

/* sidebar styles */
.sidebar {
	&>div:last-of-type {
		padding-bottom: 30px;
	}
	.btn {
		padding-left: 5px;
		padding-right: 5px;
	}
	#tryme .col-lg-4 {
		padding-left: 0px;
		padding-right: 0px;
	}
}

/* content styles */
.content.page {
	@media all and (max-width: 767px) {
		padding-top: 15px;
	}
	.header {
		display: block;
		height: 40px;
		line-height: 40px;
		text-align: left;
	}
	#map-canvas {
		@media all and (min-width: 768px) {
			height: 500px;
			margin: 30px 0px;
		}
		@media all and (max-width: 767px) {
			height: 250px;
			margin: 30px 15px;
		}		
	}
	.margin40-0 {
		margin: 40px 0px;
	}
	.cegtortenet {
		strong {
			display: block;
			margin: 5px 0px 30px;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}

/* pdf styles */
.pdf {
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	font-size: 15px;
	color: black;
	margin: 30px 0px;
	.red {
		color: @trailer-feedback-bad;
	}
	.green {
		color: @trailer-feedback-good;
	}
	@media all and (max-width: 767px) {
		.order-info,
		.item-info {
			.no-padding-right {
				padding-right: 15px;
			}
			.no-padding-left {
				padding-left: 15px;
			}
			.no-side-padding {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
	.no-padding-right {
		padding-right: 0px;
	}
	.no-padding-left {
		padding-left: 0px;
	}
	.no-side-padding {
		padding-left: 0px;
		padding-right: 0px;
	}	
	.page-break {
	    page-break-after: always;
	}
	.header-info {
		p {
			font-weight: 300;
			font-style: italic;
			margin-bottom: 3px;
		}
		p:last-of-type {
			letter-spacing: 5px;
		}
		h1.text-right {
			font-weight: 800;
			color: @trailer-gray;
		}
		img {
			max-width: 100%;
			border-top: 3px solid @trailer-yellow;
		}
		&>div {
			@media all and (max-width: 650px) {
				width: 100%;
				display: block;
			}
		}
	}
	.contact-offer {
		.name {
			border: 1px solid black;
		}
		.value {
			border: 1px solid @trailer-yellow;
			text-align: center;
		}
		.name,
		.value {
			margin-bottom: 15px;
			padding: 5px 15px;
		}
	}
	.contact-offer,
	.order-info,
	.item-info,
	.item-costs {
		margin-top: 40px;
		.header {
			background-color: @trailer-yellow;
			border-bottom: 2px solid black;
		}
		.body,
		.header {
			text-align: center;
		}
	}
	.order-info,
	.item-info {
		border: @pdf-border;		
	}
	.order-info {
		.no-padding-right,
		.no-side-padding {
			@media all and (min-width: 768px) {
				border-right: @pdf-border;
			}
		}
		.no-padding-left {
			border-right: none;
		}
		.body,
		.header {
			padding: 5px 15px;
		}
		.visible-xs-block div {
			border-bottom: @pdf-border;
		}
	}
	.item-info {
		.col-lg-6:nth-child(odd) {
			padding-right: 0px;
		}
		.col-lg-6:nth-child(even) {
			padding-left: 0px;
			span {
				border-right: none;
			}			
		}
		.col-lg-6:nth-last-of-type(1) span,
		.col-lg-6:nth-last-of-type(2) span {
			@media all and (min-width: 768px) {
				border-bottom: none;
			}
		}
		.text {
			span {
				display: block;
				padding: 5px 15px;
				height: 32px;
				border-right: @pdf-border;
				border-bottom: @pdf-border;
			}				
		}
		.left {
			@media all and (min-width: 768px) {
				border-right: @pdf-border;
			}
		}
		.image {
			img {
				display: block;
				margin: 0 auto;
			}
		}
	}
	.item-costs {
		border-top: @pdf-border;
		border-right: @pdf-border;
		overflow: hidden;
		.header,
		.costs div {
			padding: 5px 15px;
		}
		.header {
			border-left: @pdf-border;
			border-bottom: @pdf-border;
		}
		.costs {
			border-left: @pdf-border;
			border-bottom: @pdf-border;
			div:nth-of-type(odd) {
				border-right: @pdf-border;
			}
			div {
				border-bottom: @pdf-border;
			}
			div:nth-last-of-type(1),
			div:nth-last-of-type(2) {
				border-bottom: none;
			}
		}
		
	}
	.footer {
		.info,
		.company-info {
			margin-top: 40px;
		}		
		.company-info {
			background-color: @trailer-yellow;
			padding: 15px 15px 10px;
			border: 1px solid black;
		}
	}
}

/* temp styles */
.prezi {
	text-align: center;
	vertical-align: top;
	display: inline-block;
	width: 25%;
	float: left;
	padding: 5px;
	&>span {
		display: inline-block;
		float: left;
		clear: left;
		margin-top: 5px;			
	}
}

/* advanced search items */
.fullBg {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media all and (max-width: 1422px) {
		min-height: 56.25vw;
	}
	@media all and (min-width: 1423px) {
		min-height: 800px;
	}
	@media all and (max-width: 767px) {
		background-image: url('/imgs/photoslider_1.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	#adv_search_slide {
		@media all and (min-width: 768px) {
			display: block;
		}
		@media all and (max-width: 767px) {
			display: none;
		}
		pointer-events: none;
		position: absolute;
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		.carousel-inner {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			.item {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-position: center center !important;
    		background-size: cover !important;
			}
		}  		
	}
	.adv-search {
		.checkboxLabel {
			color: #555;
		}
	}
	.col-md-8 {
		@media all and (max-width: 767px) {
			padding: 0 0;
		}
	}
}
.adv-search {
	color: black;
	.yellow-bg {
		background-color: @trailer-yellow;
	}
	.trans-gray {
		background-color: rgba(244,244,244, 0.6);
	}
	.header {
		padding: 5px 25px;
		h3 {
			font-weight: 400;
			font-size: 16px;
			color: @trailer-dark-gray;
		}		
	}
	.content {
		padding: 15px 25px;
		.form-group {
			margin-bottom: 3px;
		}
		h4 {
			font-weight: 700;
			color: #444;
		}
		span {
			font-weight: 400;
		}
		h4, span {			
			font-size: 13px;
		}
		.tick-holder {
			margin-right: 10px;
		}
		#adv_search_submit {			
			margin-top: 30px;	
		}
		#refine_search_submit {
			display: none;
		}
	}
	#tryme .col-lg-4 {
		@media all and (min-width: 500px) and (max-width: 767px) {
			width: 50%;
		}
	}
}
.adv-search.refine-search {
	.head {
		overflow: hidden;
		button {
			display: none;
		}
		h6 {
			font-weight: 600;
			color: @trailer-brown;
		}
	}
	.content {
		.row > div {
			width: 100%;
		}
		#adv_search_submit {			
			display: none;	
		}
		#refine_search_submit {
			display: block;
		}
	}
}
#search_results_vue {
	@media all and (max-width: 767px) {
		padding-top: 15px;
		.opening-times {
			display: none;
		}
	}
	#toCompare {
		display: none;
		@media all and (min-width: 768px) and (max-width: 991px) {
			font-size: 10px;
		}
	}	
}
.opening-times,
.widget {
	padding-top: 30px;
}
.request-offer {
	padding-top: 10px;
	p {
		font-weight: 400;
		font-size: 12px;
	}
	button[type="submit"] {
		font-size: 20px;
		padding: 10px 25px;
		i {
			display: none;
		}
	}
	.checkboxLabel {
		display: block;
		margin-bottom: 15px;
	}
}
.widget {
	position: relative;
	@media all and (min-width: 500px) and (max-width: 767px) {
		padding-left: 50px;
		padding-right: 50px;
	}
	@media all and (max-width: 499px) {
		padding-left: 10px;
		padding-right: 10px;
	}
	img {
		max-width: 100%;
		min-width: 100%;
		width: 100%;
		height: auto;
		padding: 15px 50px 0px;
	}
	.line {
		display: block;
		width: 50%;
		margin: 25px auto 15px;
		border-bottom: 1px solid black;
	}	
	span > br {
		@media all and (min-width: 768) and (max-width: 991px) {			
			display: block;
		}
		@media all and (max-width: 767px) {			
			display: none;
		}
		@media all and (min-width: 992px) {			
			display: none;
		}
	}
}

.akcios-widget {
	.carousel-control.left,
	.carousel-control.right {
	  background-image: none;
	  background-repeat: no-repeat;
	  filter: none;
	}
	.text-uppercase {
		font-weight: 600;
		border: 1px solid rgb(68, 68, 68);
		border-radius: 4px;		
		box-sizing: border-box;
		color: rgb(255, 236, 76);
		font-size: 13px;
		line-height: 18.5714302062988px;
		padding: 5px;
		text-align: center;
		text-transform: uppercase;
		vertical-align: middle;
		display: block;
		background: @black-gradient-top-color;	
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzgwODI4NSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzU4NTk1YiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0NDQ0NDQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,  @black-gradient-top-color 0%, @black-gradient-middle-color 50%, @black-gradient-bottom-color 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@black-gradient-top-color), color-stop(50%,@black-gradient-middle-color), color-stop(100%,@black-gradient-bottom-color));
		background: -webkit-linear-gradient(top,  @black-gradient-top-color 0%,@black-gradient-middle-color 50%,@black-gradient-bottom-color 100%);
		background: -o-linear-gradient(top,  @black-gradient-top-color 0%,@black-gradient-middle-color 50%,@black-gradient-bottom-color 100%);
		background: -ms-linear-gradient(top,  @black-gradient-top-color 0%,@black-gradient-middle-color 50%,@black-gradient-bottom-color 100%);
		background: linear-gradient(to bottom,  @black-gradient-top-color 0%,@black-gradient-middle-color 50%,@black-gradient-bottom-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@black-gradient-top-color', endColorstr='@black-gradient-bottom-color',GradientType=0 );
	}
}	

.opening-times {
	p, span, a {		
		color: black;
		font-weight: 400;
	}
	p > span {
		font-size: 13px;
		display: inline-block;
		vertical-align: middle;
		width: 50%;
		&:last-of-type {
			text-align: right;
		}
	}
	p {
		font-size: 15px;
		i {
			font-size: 17px;
		}
	}
	p:nth-of-type(3) {
		margin-top: 30px;
		margin-bottom: 0px;
	}
	p:nth-of-type(4) {
		margin-top: 40px;
	}
	#map-canvas {
		height: 220px;
	}
	.nyitvatartas {
		font-weight: 800;
		font-style: italic;
		margin: 0px 0px 15px;
		font-size: 17px;
		border: 1px solid rgb(216, 183, 43);
		border-radius: 4px;		
		box-sizing: border-box;
		color: rgb(0, 0, 0);		
		font-size: 17px;		
		height: 36px;
		line-height: 24.2857151031494px;
		margin-bottom: 15px;
		padding: 5px;
		text-align: center;
		text-transform: uppercase;
		vertical-align: middle;
		display: block;
		background: @yellow-gradient-top-color; 	
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZjM5YSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U4Y2I0ZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkOGI3MmIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,  @yellow-gradient-top-color 0%, @yellow-gradient-middle-color 50%, @yellow-gradient-bottom-color 100%); 
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@yellow-gradient-top-color), color-stop(50%,@yellow-gradient-middle-color), color-stop(100%,@yellow-gradient-bottom-color)); 
		background: -webkit-linear-gradient(top,  @yellow-gradient-top-color 0%,@yellow-gradient-middle-color 50%,@yellow-gradient-bottom-color 100%); 
		background: -o-linear-gradient(top,  @yellow-gradient-top-color 0%,@yellow-gradient-middle-color 50%,@yellow-gradient-bottom-color 100%); 
		background: -ms-linear-gradient(top,  @yellow-gradient-top-color 0%,@yellow-gradient-middle-color 50%,@yellow-gradient-bottom-color 100%); 
		background: linear-gradient(to bottom,  @yellow-gradient-top-color 0%,@yellow-gradient-middle-color 50%,@yellow-gradient-bottom-color 100%); 
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@yellow-gradient-top-color', endColorstr='@yellow-gradient-bottom-color',GradientType=0 ); 
	}
}
.trailer-types-bottom,
.will-help,
.trailer-stock {
	.head {
		padding-left: 15px;
		margin-top: 15px;
		border-bottom: 2px solid @trailer-dark-gray;
		h6 {
			color: @trailer-dark-gray;
		}
	}
}
.trailer-types-bottom {
	.body {
		ul {
			margin-top: 10px;
		}
		li {
			padding-left: 10px;
			list-style: none;
			@media all and (min-width: 768px) {
				width: 50%;				
				display: inline-block;
			}				
			&:hover {
				background-color: @white-gradient-middle-color;
			}
			a {
				color: @trailer-brown;
				font-weight: 400;
				font-size: 18px;
				text-transform: capitalize;
				display: block;
				line-height: 50px;
				font-style: italic;
				text-decoration: none;
			}			
		}
	}
}
.will-help {
	.head {
		padding-top: 15px;
	}
	.body {
		padding: 40px 0px 0px;
		text-align: center;
		.info-container {
			position: relative;
			display: block;
			margin: 0px auto 15px;
			border: 1px solid @trailer-yellow;
			text-align: left;
		}
		img, .info {
			@media all and (min-width: 600px) {
				max-width: 50%;
				display: inline-block;
				vertical-align: middle;
				max-height: 300px;
			}	
		}
		img {
			height: auto;
			margin: -1px 0px 0px -3px;
			z-index: 1;
			@media all and (max-width: 599px) {
				width: 100%;
				height: auto;
				margin: -1px -1px 0px;
				display: block;
			}
		}
		.info {
			padding: 30px 15px 15px;
			word-break: break-word;
			p, a {
				color: black;
				font-size: 17px;
				font-weight: 400;
			}
			i {
				font-size: 18px;
			}
		}	
	}
}
.trailer-stock {
	.head {
		margin-top: 50px;		
	}
	.body {
		padding: 30px 0px;
	}
	ul {
		position: relative;
		li {
			list-style: none;
			text-align: center;
			margin-bottom: 15px;			
			@media all and (min-width: 1200px) {
				width: 25%;
				display: inline-block;
				img {
					height: 75px;
					width: auto;
				}
			}
			@media all and (min-width: 992px) and (max-width: 1199px) {
				width: 33%;
				display: inline-block;
				img {
					height: 75px;
					width: auto;
				}
			}
			@media all and (min-width: 500px) and (max-width: 991px) {
				width: 50%;
				display: inline-block;
				img {
					height: 75px;
					width: auto;
				}
			}
			img {
				margin: 0px auto;
				max-width: 100%;
			}
			p {
				color: black;
				text-align: center;
				margin-top: 10px;
			}
			a {
				text-decoration: none;
			}
		}
	}
}

.search-results {
	@media all and (max-width: 767px) {
		padding-top: 15px;
	}
	.header {
		padding-bottom: 15px;
		@media all and (max-width: 499px) {
			text-align: center;
		}
		.search-back {
			margin: 0px 0px 25px;
		}
		.breadcrumb {
			margin-bottom: 25px;
			background-color: transparent;
			text-align: right;
			li>a {
				color: @trailer-dark-gray;
				font-weight: 600;
			}
			li.active {
				font-weight: 800;
			}
		}
		ul.pagination {
			position: relative;
			overflow: hidden;
			margin: 0px;
			max-width: 100%;
			width: 100%;
			&>div {
				display: flex;
		    flex-wrap: wrap;
		    justify-content: flex-start;
				margin: 0 auto;
				text-align: center;
				width: 90%;
				@media all and (max-width: 500px) {
					width: 80%;
				}
			}
			.prev,
			.next {
				position: absolute;				
				top: 0;
				bottom: 0;
				width: 5%;
				@media all and (max-width: 500px) {
					width: 10%;
				}
				i {
					position: absolute;
					top: 50%;
					margin-top: -6px;
					width: 100%;
					text-align: center;
					left: 0;
					right: 0;
				}
				&:hover {
					i {
						color: @trailer-yellow;
					}
				}
			}
			.prev {
				left: 0px;
			}
			.next {
				right: 0px;
			}
			li {
				font-weight: 600 !important;
				font-size: 16px;
				color: @trailer-gray;
				margin: 0px;
				display: inline-block;
				padding: 0px 6px;
				cursor: pointer;
			}
			.page {
				border: 2px solid @trailer-gray;
				color: @trailer-dark-gray;
				font-weight: 600 !important;
				margin: 1px;
				cursor: pointer;
				&:hover {
					color: @trailer-brown;
					font-size: 16px;
				}
			}			
			li.active {
				font-size: 18px;
				color: @trailer-brown;
				background-color: transparent;
				&:hover {					
					font-size: 18px;
				}
			}
		}
		.header-text {
			color: @trailer-dark-gray;
			font-size: 13px;
		}
		.search_result_text {
			margin-bottom: 0;
			@media all and (min-width: 336px) {
				margin-top: 6px;
			}
		}
		.number-of-results {
			border-radius: 0px;
		}
		#filter_search {
			text-align: right;
			padding: 15px 30px;
			.tick i {
				color: transparent;
				display: block;
			}
			.checkboxLabel {
				font-weight: 500;
				color: @trailer-dark-gray;
				input {
					left: 0;
					right: 0;
					bottom: 0;
					top: 0;
				}
			}
			input[type=checkbox]:checked ~ .tick-holder .tick,
			input[type=radio]:checked ~ .tick-holder .tick {
				background-color: transparent;
				i {
					color: @trailer-dark-gray;
				}
			}
		}
	}
	.results {
		padding-left: 0px;
		.result {
			list-style: none;
			position: relative;
			.border {
				border-bottom: 1px solid black;
				padding: 10px 0px;
				display: flex;
				align-items: center;
			}
			img {
				height: auto;
				max-height: 100%;
				cursor: pointer;
			}
			@media all and (min-width: 600px) {
				img {
					flex: 0 0 35%;
					max-width: 35%;
				}
				.summary {
					flex: 0 0 65%;
				}
			}
			@media all and (max-width: 599px) {
				text-align: center;
				.border {
					flex-wrap: wrap;
				}
				img {
					margin: 10px auto 15px;
					flex: 0 0 100%;
					max-width: 100%;
				}
				.summary {
					flex: 0 0 100%;
				}
			}
		}
		.result:last-of-type .border {
			border-bottom: none;
		}
	}
	.results .result .summary {
		position: relative;
		@media all and (min-width: 600px) {
			padding-left: 20px;
		}
		p {
			color: @trailer-dark-gray;
			font-size: 14px;
			text-align: left;
		}
		.main-info {
			display: flex;
			@media all and (max-width: 1199px) {
				flex-wrap: wrap;
			}
			p {
				font-weight: 700;
				@media all and (min-width: 1200px) {
					flex: 1 1 33%;
				}
				@media all and (max-width: 1199px) {
					display: flex;
					align-items: center;
					flex: 0 0 100%;
					.toLeft {
						text-align: left;
						flex: 0 0 40%;
					}
					.toRight {
						text-align: right;
						flex: 0 0 60%;
					}
				}
				/*@media all and (max-width: 991px) {
					flex: 0 0 100%;
				}*/			
			}
		} 
		.add-info {			
			@media all and (min-width: 700px) {
				padding: 10px 20px;
			}
			@media all and (max-width: 699px) {
				padding: 20px 0px;
			}
			@media all and (min-width: 600px) {
				p {
					width: 49%;
					display: inline-block;
				}	
			}
			@media all and (max-width: 599px) {
				p {
					width: 100%;
					display: block;
					position: relative;
					.toLeft,
					.toRight {
						display: inline-block;
					}
					.toLeft {
						text-align: left;
						width: 40%;
					}
					.toRight {
						text-align: right;
						width: 58%;
					}
				}
			}
			p {
				font-weight: 600;
			}
		}
		.price {
			display: flex; 
			justify-content: space-between;
			@media all and (max-width: 991px) {
				flex-wrap: wrap;
			}
			&>.detailedPrice {
				p:first-of-type {
					color: black;
					font-size: 14px;
					font-weight: 400;
					line-height: 1;
				}
				p {
					font-size: 10px;
					color: @trailer-dark-gray;
					font-weight: 600;
					margin-bottom: -1px;
				}
			}				
			&>.detailedPrice,
			&>.toInfo,
			&>.compare {
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 10px;
				padding-right: 10px;
				@media all and (min-width: 1200px) {
					flex-basis: calc((100% - 20px) / 3);
				}
				@media all and (min-width: 992px) {
					flex-grow: 0;
					flex-shrink: 1;
					flex-basis: calc((100% - 10px) / 3);
				}
				@media all and (min-width: 992px) and (max-width: 1199px) {
					padding-left: 6px;
					padding-right: 6px;
				}				
				@media all and (min-width: 768px) and (max-width: 991px) {
					flex: 0 0 100%;
					margin-bottom: 3px;
					p {
						text-align: center;
					}
				}
				@media all and (max-width: 767px) {
					flex: 0 0 100%;
					margin-bottom: 3px;
					p {
						text-align: center;	
					}
				}
			}
			&>.detailedPrice {
				text-align: left;
				border-radius: 0px;				
				p:first-of-type {
					font-weight: 400;
				}
			}
			&>.toInfo {
				color: @trailer-dark-gray;
				border-radius: 0px;
			}
			.compare {
				color: @trailer-dark-gray;
				font-weight: 700;
				font-size: 12px;
				i {
					vertical-align: middle;
					margin-right: 10px;
					color: @yellow-gradient-middle-color;
					font-size: 25px;
				}
				span {					
					display: inline-block;
					line-height: 1;
					vertical-align: middle;
				}
			}
		}
	}
}
.single-result {
	@media all and (min-width: 768px) {
		padding: 0px 0px 20px;
	}
	@media all and (max-width: 767px) {
		padding: 15px 0px 20px;
	}
	
	@media all and (min-width: 992px) {
		.content-left {
			padding-right: 0px;
		}
		.content-right {
			padding-left: 0px;
		}
	}
	.resources {
		button {
			font-size: 14px;
			padding: 10px 0px;
			border-radius: 0px;
			@media all and (min-width: 992px) {
				display: inline-block;
				width: 32%;
				margin-top: 15px;
				margin-bottom: 15px;
			}
			@media all and (min-width: 768px) and (max-width: 991px) {
				display: block;
				width: 100%;
				margin-bottom: 5px;
				text-align: center;
			}
			@media all and (min-width: 650px) and (max-width: 767px) {
				display: inline-block;
				width: 32%;
				margin-top: 15px;
				margin-bottom: 15px;
			}
			@media all and (max-width: 649px) {
				display: block;
				width: 100%;
				margin-top: 5px;
				margin-bottom: 5px;
				text-align: center;
			}
		}
		&>span {
			font-size: 14px;
			padding: 10px 0px;
			border-radius: 0px;
			display: block;
			width: 100%;
			text-align: center;
			border: 1px solid @trailer-yellow;
			background-color: @trailer-yellow;
			color: @trailer-dark-gray;
			font-weight: 600;
			@media all and (min-width: 650px) {
				margin-top: 15px;
				margin-bottom: 15px;
			}
			@media all and (max-width: 649px) {
				margin-top: 15px;
				margin-bottom: 15px;
			}
		}
	}
	.info {
		overflow: hidden;
		.toToggle {
			display: none;
		}
		.toToggle.active {
			display: block;
		}
		#termek-info {
			.zoomed {
				width: 98%;
				padding-top: 15px;
				@media all and (min-width: 768px) {
					min-height: 500px;
				}
				img {
					width: 100%;
					height: auto;
					max-width: 100%;
					margin-top: 15px;
					margin-bottom: 15px;
				}
			}
			.thumbs {
				padding-left: 15px;
				padding-right: 30px;
				margin-bottom: 15px;
				.thumb {
					height: auto;
					padding: 15px;
					&:hover {
						border: 1px solid @trailer-yellow;
						border-radius: 3px;
					}
				}
				.thumb.current {
					border: 1px solid @trailer-yellow;
					border-radius: 3px;
				}
			}
		}
		#letolthetok {
			i {
				font-size: 44px;
			}
			i.second {
				font-size: 20px;
				right: -20px;
				top: -10px;
				position: absolute;
			}
			.name {
				width: 30%;
				a, span {
					text-decoration: none;
				}
				span {
					margin-top: 10px;
					color: black;
				}
			}
			.description {
				width: 69%;
			}
			.name,
			.description {
				display: inline-block;
				vertical-align: middle;
				padding: 15px 0px;
			}
		}
		button {
			font-size: 16px;
			border-radius: 0px;
			padding: 10px 20px;
		}
		.price-info {
			padding: 20px 40px;
			background-color: @trailer-light-gray;
			border: 1px solid @white-gradient-bottom-color;
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
			position: relative;
			display: none;
			p {
				font-weight: 300;
				font-size: 11px;
			}
			&:before {
				content: '';
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				border-bottom: 10px solid #f4f4f4;
				position: absolute;
				left: 10%;
				top: -10px;
			}				
		}
		#kiegeszitok {
			td {
				padding: 5px 3px;
				.trailer-checkbox label {
					margin: 0px;
				}
			}
		}
		.trailer-table {
			width: 97%;
			td {
				img {
					padding: 5px;					
					margin: 0px auto;
				}
			}
		}
	}
	.price-calculator-bg {
		background-color: @trailer-light-gray;
	}
	.price-calculator {
		padding: 15px 10px 0px;
		.item-info {
			padding-bottom: 15px;
			p {
				font-weight: 400;
				font-size: 13px;
				margin-bottom: 5px;
				clear: left;
				height: 15px;
				color: black;
				span {
					text-align: left;
					display: inline-block;
					width: 50%;
					float: left;
				}
			}
		}
		.accessories {
			clear: both;
			padding: 3px 0px;
			margin-top: 5px;
			p {
				font-size: 13px;
				margin-bottom: 5px;
			}
			.single-accessory {
				position: relative;
				padding-top: 5px;
				border-top: 1px solid @trailer-gray;
				i {
					position: absolute;
					right: 5px;
					top: 5px;
					opacity: 0.4;
					font-size: 16px;
					transition: .2s;
					&:hover {
						opacity: 1;
					}
				}
			}
			.single-accessory:last-child {
				border-bottom: 1px solid @trailer-dark-gray;
			}
		}  
		.price {
			clear: both;
			padding: 15px 0px;
			.netto,
			.brutto,
			.euro {
				color: black;
				font-weight: 700;
			}
			.netto {
				font-size: 22px;
				margin-bottom: 0px;
			}
			.brutto,
			.euro {
				font-size: 14px;
			}
			.euro {
				margin: 25px 0px 10px;
			}
		}
	}
}
/* compare items */
.compare-items {
	.search-results {
		overflow: hidden;
		margin: 30px 0px;
		.header {
			background-color: @trailer-yellow;
			text-align: left;
			font-weight: 400;
		}
		.col-lg-3:last-of-type {
			border-right: @compare-items-border;
		}
		.col-lg-3.button-holder:last-of-type {
			border-right: none;
		}
		.col-lg-3 {
			padding: 5px 15px;
			text-align: center;
			border-left: @compare-items-border;
			border-top: @compare-items-border;
		}
		.row:last-of-type {			
			border-bottom: none;
		}
		.compareRemove {
			margin: 10px 0px;
		}
		.button-holder {
			border-top: @compare-items-border;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	.search-results.no-items {
		border: none;
	}		
}

.galery {
	@media all and (max-width: 767px) {
		padding-top: 15px;
	}
	.thumb {
		text-align: center;
		padding-bottom: 20px;
		margin-bottom: 0px;
		@media all and (min-width: 992px) {
			height: 160px;
		}
		@media all and (min-width: 768px) and (max-width: 991px) {
			height: 200px;
		}
		@media all and (min-width: 570px) and (max-width: 767px) {
			height: 240px;
		}
		@media all and (max-width: 569px) {
			width: 100%;
			height: auto;
		}
		.thumbnail {
			height: 100%;
			margin-bottom: 0px;
			img {
				height: auto;
				width: 100%;
				margin: auto 0px;
				max-height: 100%;
				max-width: 100%;
				display: block;
			}
		}
			
	}
}
.main.videos {
	@media all and (max-width: 767px) {
		padding-top: 15px;
	}
	.body { 
		h5 {			
	  		span {
	  			display: inline-block;
				vertical-align: middle;
	  		}
		}
		a {
			color: #848484;
			text-decoration: none;
	  		&:hover {
				color: #848484;
				text-decoration: none;
			}
		}	
		i {
			font-size: 50px;
			vertical-align: middle;
			text-align: center;
			margin-right: 10px;
			@media all and (min-width: 768px) {
				width: 10%;
			}
			@media all and (max-width: 767px) {
				width: 15%;
			}
  		}
  		.infos {
  			display: inline-block;
  			vertical-align: middle;
  			@media all and (min-width: 768px) {
  				width: 85%;
			}
			@media all and (max-width: 767px) {
				width: 80%;
			}
  		}
	}
}
.main.video-show {
	h4, p {
		color: #848484;
	}
	.emb-vid {
		position: relative;
		padding-top: 56.25%;
		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
	}
}
footer {
	padding: 30px 0px 60px;
	margin: 0px;
	.trailer-black-gradient;
	@media all and (max-width: 768px) {
		text-align: center;
	}
	.goUp {
		color: @trailer-dark-gray;
		font-weight: 400;
		position: fixed;
		right: 30px;
		bottom: 265px;
	}
	p, li {
		color: white;
	}
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	li {
		line-height: 35px;
		list-style: none;
		font-weight: 300;
		font-size: 17px;
		letter-spacing: .5px;
		&:hover {
			color: @trailer-yellow;
		}	
	}
	ul {
		padding-left: 0px;
		margin-top: 15px;
	}
	.border {
		border-bottom: 1px solid @trailer-dark-gray;
	}
	.header {
		font-size: 17px;
		font-weight: 700;
	}
}

/* datatables */
.dataTable td a {
    display: inline-block;
    width: 33%;
    margin: 2px 0px 0px 0px;
}
.dataTables_paginate {
	padding: 15px 0px 30px;
	a {
		padding: 3px 5px;
	}
	a.current {
		font-size: 18px;
	}
}
@single-product-gutter-top: 35px;
@single-product-gutter-bottom: 40px;
@single-product-gutter-right: 15px;
@single-product-gutter-left: 15px;
@product-attribute-arrow-height: 15px;
@product-attribute-arrow-width: 15px;
@product-attribute-border-radius: 10px;
@product-attribute-bg-color: @trailer-light-gray;
@product-attribute-shadow-bg-color: 1px 1px 20px 0px #CECCCC;
@single-product-animation-delay: 0.5s;
@media all and (max-width: 767px) {
	.magazinok .singleProduct,
  .magazinok .singleProduct .right,
  .magazinok .singleProduct .left,
  .layout-two .magazinok .singleProduct,
  .layout-two .magazinok .singleProduct .right,
  .layout-two .magazinok .singleProduct .left,
  .layout-one .magazinok .singleProduct,
  .layout-one .magazinok .singleProduct .right,
  .layout-one .magazinok .singleProduct .left {
    padding: 0 15px 40px;
  }
}
.magazinok {
	@media all and (max-width: 767px) {
		button {
			display: block;
			width: 100%;
			margin-bottom: 5px;
		}
	}
	img {
		max-height: 100%;
		max-width: 100%;
		height: auto;
		width: 90%;
		margin-left: 5%;
		margin-right: 5%;
	}
	.singleProduct {
		.product-info {
			padding-bottom: 3px;
		}
		.product-price {
			padding: 0px 0px 10px;
			margin-bottom: 10px;
	    color: @trailer-feedback-bad;
			font-weight: 600;
			.price {
				font-size: 32px;
				text-align: left;
		    text-shadow: 1px 1px 3px @trailer-dark-gray;
			}
			.from {
				font-size: 14px;
				margin-left: 3px;
				text-align: right;
				text-shadow: 1px 1px 3px @trailer-dark-gray;
			}
		}
		.info {
			ul {
				padding-left: 0px;
				list-style: none;
			}
			li {
				line-height: 20px;
				margin-bottom: 4px;
				font-size: 12px;
			}
			li i {
				height: 20px;
		    width: 20px;
		    border-radius: 13px;
		    margin-right: 4px;
		    text-align: center;
		    background-color: @trailer-feedback-good;
		    color: white;
		    line-height: 22px;
		    padding-left: 3px;
		    font-size: 11px;
			}
			li span {
				font-weight: 400;
				color: @trailer-dark-gray;
			}
		}
		.shadow {
			position: relative;
	    box-shadow: @product-attribute-shadow-bg-color;
	    border-radius: @product-attribute-border-radius;
	    .arrow {
				position: absolute;
				width: @product-attribute-arrow-width;
				height: @product-attribute-arrow-height;
				background-color: @product-attribute-bg-color;
				transform-origin: center;
				transform: rotateZ(-45deg);
				box-shadow: @product-attribute-shadow-bg-color;
				z-index: 0;
	    }
	    .arrow.left {
	    	left: 10%;
	    	top: -@product-attribute-arrow-height/2;
	    }
	    .arrow.right {
	    	bottom: 20%;
	    	right: -@product-attribute-arrow-width/2;
	    }
		}
		.product-attributes {
			border-radius: @product-attribute-border-radius;
			position: relative;
			background-color: @product-attribute-bg-color;
			color: white;
			padding: 10px 15px;
			margin-top: @product-attribute-arrow-height;
			.product-manufacturer,
			.product-type {
				display: inline-block;
				vertical-align: middle;
				text-transform: uppercase;
				font-weight: 600;
				color: @trailer-dark-gray;
			}
			.product-manufacturer {
				margin-right: 5px;
			}
			.nameField,
			.priceField {
				display: inline-block;
				vertical-align: middle;
				color: @trailer-dark-gray;
				width: 50%;
				p {
					margin-bottom: 0px;
				}
			}
			.priceField {
				text-align: right;
			}
			.attributes {
				padding: 5px 0px 0px;
				color: @trailer-dark-gray;
				p {
					margin-bottom: 3px;
				}
				.name {
					font-weight: 600;
					font-size: 13px;
				}
				.value {
					font-weight: 300;
					font-size: 12px;
				}
				.half {
					display: inline-block;
					vertical-align: middle;
					width: 50%;
				}
				.half:nth-child(2) {
					text-align: right;
				}
			}
		}
	}
	.singleProduct .right {
		@media all and (min-width: 768px) {
			padding-left: @single-product-gutter-left;
			padding-top: @single-product-gutter-top*3;
			margin-bottom: -@single-product-gutter-bottom;
		}
		.product-price {
			margin-bottom: 0px;
			padding: 0px;
			.price {
				text-align: right;
			}
		}
		.info {
			padding-top: 30px;
		}
	}
	.singleProduct .left {
		@media all and (min-width: 768px) {
			padding-right: @single-product-gutter-right;
		}
	}
}
.layout-two {
	@media all and (min-width: 768px) {
		.singleProduct .left {
			margin-bottom: -350px;
		}
		.singleProduct .right {
			margin-bottom: -250px;
		}
		.singleProduct .full {
			margin-top: 280px;
		}		
	}
	.singleProduct .left {
		@media all and (min-width: 768px) {
			padding-bottom: @single-product-gutter-bottom;
		}
		.info {
			position: absolute;
			top: 0;
			z-index: 1;
			.product-price .price,
			.product-price .from {
				text-align: right;
			}
		}			
	}
	.singleProduct .right {
		@media all and (min-width: 768px) {
			padding-left: @single-product-gutter-left;
		}
		padding-top: 0;
		padding-bottom: 0;
		.info {
			position: absolute;
			top: 0;
			z-index: 1;
			.product-price .price,
			.product-price .from {
				text-align: right;
			}
		}			
	}
	.singleProduct .full {
		.arrow.right {
			bottom: initial;
			top: 80%;
		}
	}
	.singleProduct .price-right {
		.info {
			right: 0;
		}
	}
	.singleProduct .price-left {
		.info {
			left: 0;
		}
	}
}	
